import request from './request';

import { FLEX_V2_API } from '../constants/apis';

/**
 * Calls Flex (V2)'s unified zipcode execution endpoint to retrieve valid zip
 * code and city locations for the passed zip code. This is used to ensure the
 * validity of the zip code, so tax can be calculated accordingly.
 *
 * @param zip The zip code to validate.
 * @returns The API response containing all locations matching that zip code.
 */
export default function flexValidateZip(zip) {
  const url = `${FLEX_V2_API}/execution/v1/subscription/zipcode`;

  const query = {
    zipcode: zip,
  };

  return request(url, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(query),
  })
    .then(response => {
      // TODO WEB-23358: Handle request response and remove console log.
      const isValidZip = response.data.success;

      console.log(isValidZip);
      return isValidZip;
    })
    .catch(() => {
      // TODO WEB-23358: Handle error response and remove console log.
      return false;
    });
}
