import React from 'react';
import PropTypes from 'prop-types';
import {
  CVC as validateCVC,
  REQUIRED as validateRequired,
  ZIP as validateZip,
  validateNameOnCard,
} from '../constants/validators';
import SecuritySymbol from './SecuritySymbol';
import CreditCardField from '../containers/CreditCardNumberContainer';
import CreditCardName from '../containers/CreditCardNameContainer';
import CardExpiryField from '../containers/CardExpiryFieldContainer';
import NumberField from '../containers/NumberFieldContainer';
import { Experiment, Treatment } from './Experiment';
import FeatureFlag from './FeatureFlag';
import { CAT_43 } from '../constants/experiments';
import { ERROR_TEXT } from '../constants/errorText';
import InputError from './InputError';
import flexValidateZip from '../api/FlexV2ZipCode';

require('../styles/billing.scss');

class CreditCardForm extends React.Component {
  render() {
    const {
      isPrefilled,
      onPrefilledFieldsChanged,
      displayPlaceHolder,
      isValidateZipEnabled,
    } = this.props;

    return (
      <div className="payment_method">
        <Experiment>
          <Treatment developerId={CAT_43.TREATMENT2}>
            <SecuritySymbol
              className="payment_method__security-symbol"
              imgType="nocircle"
            />
          </Treatment>
        </Experiment>
        <FeatureFlag feature="cardholder-full-name">
          <div className="field__cc_details">
            <CreditCardName
              className="field_cardholder_name"
              displayName="NAME ON CARD"
              isPrefilled={isPrefilled}
              messages={{
                invalid: <InputError message={ERROR_TEXT.REQUIRED_NAME} />,
              }}
              model="ownerFullName"
              onFieldChangeStarted={onPrefilledFieldsChanged}
              placeHolder={displayPlaceHolder ? 'Name on card' : null}
              validators={{
                required: validateRequired,
                invalid: validateNameOnCard,
              }}
            />
          </div>
        </FeatureFlag>
        <CreditCardField
          displayName="CARD NUMBER"
          placeHolder={displayPlaceHolder ? 'Card Number' : null}
          model="creditCard"
          tooltip={
            <p>
              We ask for your payment information so you can enjoy Hulu
              uninterrupted after your free trial ends.
            </p>
          }
          className="field_credit_card"
          onFieldChangeStarted={onPrefilledFieldsChanged}
          isPrefilled={isPrefilled}
        />
        <div className="field__cc_details">
          <CardExpiryField
            displayName="EXPIRATION"
            model="expiry"
            className="field__expiry_cvc_zip"
            onFieldChangeStarted={onPrefilledFieldsChanged}
          />

          <NumberField
            displayName="CVC"
            placeHolder={displayPlaceHolder ? 'CVC' : null}
            form="payment"
            model="cvc"
            maxLength="4"
            validators={{
              invalid: value => validateCVC(value),
            }}
            messages={{
              invalid: <InputError message={ERROR_TEXT.VALID_CODE} />,
            }}
            tooltip={
              <div>
                <div className="tooltip__image-cvc" />
                <p>
                  MasterCard, Visa, and Discover security codes are located on
                  the back of the card and are typically a separate group of 3
                  digits to the right of the signature.
                </p>
                <p>
                  American Express security codes are 4 digits located on the
                  front of the card and usually towards the right.
                </p>
              </div>
            }
            className="field__expiry_cvc_zip"
          />

          <NumberField
            displayName="ZIP CODE"
            placeHolder={displayPlaceHolder ? 'ZIP' : null}
            form="payment"
            model="zip"
            maxLength="5"
            validators={{
              invalid: validateZip,
            }}
            messages={{
              invalid: <InputError message={ERROR_TEXT.VALID_ZIP} />,
            }}
            className="field__expiry_cvc_zip"
            onFieldChangeStarted={onPrefilledFieldsChanged}
            // TODO WEB-23358: Instead of calling this onBlur, implement the validation.
            // As-is, the onBlur handler is just an invisible way to test the API integration.
            onBlur={() => {
              if (isValidateZipEnabled) {
                flexValidateZip('90210');
              }
            }}
          />
        </div>
      </div>
    );
  }
}

CreditCardForm.propTypes = {
  onPrefilledFieldsChanged: PropTypes.func.isRequired,
  isPrefilled: PropTypes.bool.isRequired,
  displayPlaceHolder: PropTypes.bool.isRequired,
  isValidateZipEnabled: PropTypes.bool,
};

export default CreditCardForm;
